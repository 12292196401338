<template>
  <div class="page" :style="{ marginTop: pageWidth < 700 ? '-40px' : '' }">
    <div v-if="this.$route.query.path == 'page1'">
      <h1>拼车市场，趣出行：大浪淘沙，另辟蹊径！</h1>
      <h1>2018-03-31 22:53</h1>
      <p>
        近日，互联网出行领域一片火热，正在大家围观美团打车和滴滴大战的时候，猝不及防杀出来一个“高德顺风车”！那目前半死不活的拼车市场，高德进来能搞明白吗？
      </p>
      <p>
        3月27日，高德地图高调宣布推出顺风车业务，将在成都、武汉两地率先上线，同时开启北京、上海、广州、深圳、杭州等城市的车主招募，之后将扩展到全国更多城市。高德顺风车不抽佣，不参与补贴战，“还原顺风车本质”。在发布会上，高德集团总裁刘振飞表示：顺风车本是非常绿色的共享出行方式，在不增加道路压力的情况下，撮合共同出行需求，可以有效提高社会出行效率，缓解城市交通拥堵，降低空气污染。
      </p>
      <p>
        尽管高德的口号是“还原顺风车本质”，但是它并没有做彻底，高德顺风车其实就是一种廉价的快车！除了0佣金、价格比快车略低之外，拼车的模式依然是滴滴那一套！比如，车主还是要绕路去接送乘客，而乘客和车主的关系也只是“一次性顺风车”。
      </p>

      <div>
        <h3>另一种视角，高德拼车其实是“被逼无奈”！ </h3>
        <p>事实上，高德地图进入出行领域只是化被动为主动。</p>
        <p>
          从自身来看，作为国内最大的移动出行平台，高德地图如今拥有7亿用户，平均每天为用户提供高达3.4亿次的出行路线规划。但这些数据，已经很多来自滴滴用户（顺风车/快车/专车/OFO/小蓝单车等）的间接使用。假想，如果有一天，滴滴做出调整，自己做地图，或者只接入腾讯地图，高德是否还有招架的能力。
          所以，高德，只是提前出击，变被动为主动罢了。
        </p>
        <p>公益的噱头，对于商家来说，从来都只是理由的一部分。看官觉得呢？ </p>
      </div>

      <div>
        <h3>为什么拼车市场一直不温不火？ </h3>
        <p>
          共享出行的概念早在几年前就有了，拼车市场从51用车、天天用车、AA拼车等，再到现如今改名了的嘀嗒出行，都一直是不温不火的状态，半死不活。
        </p>
        <p>
          顺风车在与快车、专车的竞争中被“快车化”，很多用户被养成了顺风车当廉价快车用的习惯（车接车送，小巷子你也得给我进去）。
        </p>
        <p>
          对于真正想顺路拉乘客的车主而言，钱虽然重要，但不是最重要的，在匹配度不高的情况下，现在的定价，其实也是很鸡肋的。比如，80%匹配的行程，绕路接送的距离通常会很远，有时候赚的钱还抵不过绕路烧的油钱。而且绕路接送并不顺路的乘客，很容易耽误自己的事情，小编自己也偶尔会用滴滴的顺风车，平均每一单顺风车，车主大约要多花掉30分钟的时间用于接送。真正的顺风车车主，反而被“边缘化”了。
        </p>
        <p>
          所以，目前的整个拼车市场，看似热热闹闹，实际情况是，乘客觉得价格还是太高，只会在偶尔出行又不着急的情况下，才使用顺风车。真正想顺路载几个乘客的车主，在平台里拉到合适的乘客这种事情也变得越来越难。偶尔遇到很顺路的又是高频次的，就直接以后都线下了。
        </p>
      </div>

      <div>
        <h3>趣出行：大浪淘沙，另辟蹊径！</h3>
        <p>
          趣出行不仅仅是一个顺风拼车平台，它是一次对拼车业务进行彻底颠覆的尝试，旨在重新定义这一领域的未来。他们认为，拼车的本质是一种共享出行方式，而这种方式也可以更加智能、高效，为用户提供更便捷、经济、环保的出行体验。
        </p>
        <p>
          趣出行引入了通证经济的概念，这是一个基于区块链技术的创新。他们发行了专属的通证，使得用户和车主可以通过参与平台生态系统来获得通证奖励。这种激励机制鼓励用户更积极地使用拼车服务，同时也吸引了更多的车主加入。通证经济的引入不仅可以为用户提供更多的实惠，还有助于建立一个更加公平、透明的拼车社区。
        </p>
        <p>
          趣出行注重提高乘客与车主之间的匹配度，通过智能算法和大数据分析，他们能够更准确地匹配同一路线上的乘客和车主。这意味着车主不再需要绕远路，也能更高效地接送乘客，节省时间和成本。这种智能拼车的模式使得拼车变得更加便捷，也更加吸引用户和车主的参与。
        </p>
        <p>
          趣出行的最大创新在于将智能拼车与通证经济相结合。这一模式通过智能匹配、激励机制和庞大的拼车网络，实现了出行的高效、经济和环保。用户通过拼车能够省钱。车主也受益于更高效的路线和通证奖励。这种创新模式不仅为用户提供了更好的服务，还建立了一个共赢的生态系统，为拼车业务带来了新的活力。
        </p>
        <p>
          总之，趣出行通过引入通证经济、智能拼车和星海模式，重新定义了拼车的未来。他们的目标不仅是提供便捷的出行方式，还致力于打造一个公平、高效、可持续的拼车社区。在这个新的拼车模式下，用户和车主都能够获得更多的好处，同时也有助于改善城市出行的效率和环境。
        </p>
      </div>
    </div>

    <div v-if="this.$route.query.path == 'page2'">
      <h1>颠覆传统，区块链助力趣出行创新共享出行</h1>
      <h1>2019-01-03 13:21</h1>
      <p>
        移动互联网的到来给传统出行市场带来了巨大颠覆，给传统出行市场带来了新品类：网约车。
      </p>
      <p>
        网约车战场在经历了两年多时间的“一超多强”格局之后，不但没有出现硝烟消减的迹象，反而因为顺风车事故频发，让整个国内网约车战局又增添了更多不确定性。
      </p>
      <p>
        区块链的全面落地也正在给全世界带来一种全新的面貌。趣出行基于区块链技术顺风车的出现，令网约车市场又增添了新的可能性。“趣出行”是一款基于区块链技术，利用区块链的分布式存储、去中心化、共识机制和TOKEN激励等特点，同时以TOKEN经济模型为核心，具备“去中介化”、“价值回馈”、“数据透明”和“社区共识”等特点的C2C共享出行服务DAPP。
      </p>

      <div>
        <h3>趣出行想法的萌芽</h3>
        <p>
          趣出行创始人张冬冬以前在微软就职时住在昌平，坐地铁上班不仅需要一个多小时甚至更久的时间，还经常没有座位。而另一种出行方式大巴车线路又比较复杂，需要环城区接乘客，并且定时定点，如果错过了，那就需要支付高额的打车费去公司。冬天他站在公交车站等车，望着道路上川流不息的车辆时，张冬冬不禁思考：既然是一条笔直道路的两端，为什么没有车愿意停下来载一下顺路的行人？自此，这个问题便一直留在了他的心里，开始生根发芽。
        </p>
        <p>
          后来张冬冬偶然发现在微信上有一种线路拼车群，车主在群内发布出行信息，有出行线路重合的乘客去私聊车主，这样基本三十公里十五元，不仅大大降低了打车出行的费用，并且还减轻了社会交通压力。张冬冬看到并决定抓住这个机会，2016年12月29日，趣出行在中关村成立。
        </p>
      </div>

      <div>
        <h3>“从互联网到区块链，我们走了不少弯路”</h3>
        <p>
          在顺风车上，滴滴垄断的寡头局面已然形成。抢占了整个国内市场四分之三以上的市场，其余的参与者只剩喝汤的机会，张冬冬认为在这样的大市场环境下选择长期合乘无疑是一种新的机会。
        </p>
        <p>
          比如燕郊到上地，这条路可能有几千个车主和乘客，加起来可能有上万的人在这条路上往返。乘客如果用滴滴或者滴答顺风车上班，一天的成本就要一百多，很少会有人选择长期通过这种交通方式出行。但实际情况是有很多车主也住在燕郊，他每天往返车空着也是空着，所以自然而然会形成这种线路群，每个车主带两三位乘客，每位乘客每天出行成本三四十块钱，车主既可以把油费和高速费赚回来，乘客也有了便捷舒适的出行方式，还会为道路交通减轻压力。
        </p>
        <p>
          趣出行起初是从APP出发的，运营一年之后发现并没有太多用户愿意停留在APP上面，想要把整个微信线路群的生态迁移到APP上面来并不是一件容易的事。虽然用户留在APP上的数据可以让趣出行将所有的行程数据统一化，为用户做智能匹配，但用户往里面迁移的动力依然很小。面对这个问题，趣出行团队反复琢磨，尝试了很多方式，直到他看到了区块链，了解到通证经济，发现其本质与他的初心达到了高度契合。
        </p>
        <p>
          在张冬冬的理解里，通证经济使大家形成一个庞大的生态，所有人都参与到平台里，每个人对平台产生贡献就可以得到回报，用户参与的越早回报就越高，大家谁贡献的多谁得到的也就相应更多。这样就可以充分地把每一位用户的积极性都调动起来。
        </p>
        <p>
          在传统的互联网模式里，APP很难留住用户，因为APP不可能同一时间将所有车主都迁移进来，这需要时间去完成。用户在此期间发现平台无法满足他们的需求，就会将其卸载，张冬冬说这是传统APP最大的弊端，而基于区块链和TOKEN经济模型建设就会解决这个问题，将用户留在趣出行里。
        </p>
        <p>
          在传统互联网模式里，趣出行在过去十八个月积累了十万用户，融合区块链之后，两个月新增四十万多用户，每天APP下载量至少三千到五千。
        </p>
      </div>

      <div>
        <h3>熟人护航，拼车更安全</h3>
        <p>
          现阶段的人工审核和技术识别，都无法分辨握着方向盘的究竟是暴徒还是良民。
        </p>
        <p>
          近两年，头部垄断的滴滴顺风车业务事故频发，“顺风车”一度成为一个很敏感的词汇，在谈到滴滴的事，张冬冬认为滴滴出事情有几个原因，首先滴滴盘子太大了，从概率学角度来说，确实更容易出事。第二，人冒险的动机肯定有很多的，可能是偶发，也可能是蓄谋已久，但其实顺风车业务最大的问题是平台并不知道车主是一个怎么样的人，平台只能通过所谓的大数据看看他以前有没有作奸犯科的记录，但这个其实意义不大。因为很多时候，犯罪是偶发的。其实最了解一个人的其实是他身边的那些人，平台不可能了解得很详细。只要车主不触犯法律，不会记录下来，平台就很难知道你到底是个什么样的人。确实如此，你不太可能把司机群里的污言秽语当作评判依据，自以为幽默地讲一些黄段子本来或许就是相当一部分中国人的特殊嗜好。
        </p>
        <p>
          趣出行在保障用户安全上花了不少心思，不仅有安全码、一键报警、职业认证、匿名举报等，还拥有了一个新渠道“熟人认证”。车主来到趣出行平台后平台会找车主身边的人对他进行认证，每个人身边的人对他的了解程度是大数据难以比拟的。在传统互联网模式，平台其实很难将用户的积极性调动起来，但利用区块链TOKEN激励的特点，却可以完美地契合这一点。如果用户真的证明车主是个好人或者坏人平台就给你相应的奖励，之后时间三个月，六个月，一位车主信用度良好，真的不做什么坏事确实是个好人，用户就会获得更多的奖励。
        </p>
        <p>
          在去中心化的世界里，大家更多的想法是把这个生态一起做好。其实区块链世界的人很单纯，他们持有token，就希望token更值钱。
        </p>
        <p>
          在解决网约车的安全问题上，趣出行一直在尽可能把用户了解的更透彻些，将风险指数降到更低。
        </p>
      </div>

      <div>
        <h3>“让用户记住一个纯粹的拼车软件，其实很难”</h3>
        <p>
          “我们想要做的是一个拼车鲜活经济体，拼车只是我们的一个切入体，因为如果让用户记住一个纯粹的拼车软件，其实很难，但我如果让这个软件深入到他的生活中，这个时候你再让他通过这个软件去拼车的话，其实会更加容易。”
        </p>
        <p>
          用户拼车的需求不仅高频，而且急迫。如果满足用户即时的需求，想留住用户，还需要更多样的设计。未来趣出行还会相继开通线上生活模块。
        </p>
        <ul>
          <li>广告投放</li>
          <p>
            趣出行正在研发广告版块。用户可以用持有的CPLE（趣出行平台通证）在平台上自助投放广告，也可以直接用现金投放，用户自己家的农产品以及自己的商铺都可以在上面进行展示，大幅提升的平台的趣味性。
          </p>
          <li>社区直采</li>
          <p>
            趣出行利用平台天然的线路优势，积累了大量的车主资源，每辆车的后备厢其实都是一笔不小的空间资源。趣出行目前正在考虑社区直采。比如平台从山东产地进几箱苹果，拉到北京某一线路点。车主可以选择自行购买或者带到公司去推销，交易也无需用户抵押现金，抵押CPLE即可。很多用户对CPLE的价值都很认可，如果用户无须花钱，那直采的体系就很容易建立。
          </p>
          <li>会员权益</li>
          <p>
            未来趣出行将会给会员带来更多专项权益，大家在平台得到的CPLE不仅可以用来在趣出行DAPP中打车支付车费，还可以买东西，投电商广告等等，为广大用户提供生活服务一站式平台。
          </p>
        </ul>
      </div>

      <div>
        <h3>一座城市 一个人 一份力量</h3>
        <p>
          网约车平台若想做好，用户量很重要。这个事情是理想很丰满，现实很骨感。当你的用户量撑不起这个理想的时候，它其实就是个理想而已。趣出行为提升用户量打开平台流量入口设置了“城市节点”。
        </p>
        <p>
          节点就是那些坚定地信仰CPLE，热心于当地拼车业务的志愿者。节点负有组织当地官方拼车社群、解决用户有关产品使用和业务推广过程中遇到问题的工作，是各地用户和趣出行官方沟通的枢纽。
        </p>
        <p>
          目前，趣出行已经在深圳、成都、郑州、南京等众多城市拥有“城市节点”。
        </p>
        <p>
          平台没有做过多的宣传活动。目前主要依赖于口碑传播。因为平台内有CPLE的奖励机制存在。曾经有过一个记录，一位普通用户曾吸引大量新用户进入平台，高达3000多个。无疑这是对我们信任的最好宣传。
        </p>
      </div>

      <div>
        <h3>万般皆变，唯真心和脚踏实地不可负</h3>
        <p>
          趣出行虽然已经成立了两年，但18年十二月底上线的新的APP依然处于起步阶段，趣出行在不停地在试错，不停地摸索。“你总感觉方向明朗了，但做的时候发现用户却不买单。你只能也必须相信，下个月会好起来。我们现在是把通证经济用在了正确的时间点上，我相信，不远的将来一定会出现一家把通证经济用的恰到好处的企业。”
        </p>
        <p>
          面对现在的区块链
          “寒冬”，张冬冬信心满满。他说趣出行做的是实业，牛市熊市对于他们来讲，是不会有太大影响的。趣出行建立的理念就是以后的大部分收益拿出来分给持有CPLE的用户
        </p>
        <p>
          面对区块链的未来，区块链本身是很好的东西，它之所以没有发展起来，是因为人心。很多人就是抱着割韭菜的心来的，人心不正自然就发展不起来。张冬冬：“我觉得我们公司一定会将区块链真正落地下来，这和我的创始初衷有很大关系。我其实一直觉得，从小到大我一直是个好人。别人可以占我的便宜，但我不愿意去占别人的便宜。”
        </p>
        <p>
          打江山时以维护消费者为幌子打下市场，当权力集中过后，资本的丑陋獠牙开始显露。守江山时却将收割目的放在第一位，这种打法似乎成了所有在互联网巨头胜利者的通病。
        </p>
        <p>
          现有的共享经济模式虽然有效地挑战了传统，并且带有强烈的创新色彩，但是，它采用的依然是非常传统的商业模式。因此，打造真正开放的、去中心化的共享经济平台，同时建立需求方和供给方的信任成为共享经济发展模式的本质问题。基于区块链去中心化技术的网约车平台是一个大胆的尝试。
        </p>
        <p>只有脚踏实地的人，大地才愿意留下他的脚印。</p>
        <p>
          相信趣出行顺风车平台，厚积薄发，终将在中国网约车平台占有一席之地。未来道路上将能看到更多的趣出行顺风车，让更多的闲置资源得到有效利用，乘客享受到低价、便利的出行体验，共同建设和谐文明的出行环境。就像他们所说，“万般皆变，唯真心和脚踏实地不可负。”
        </p>
      </div>
    </div>

    <div v-if="this.$route.query.path == 'page3'">
      <h1>
        重大利好！国家正式支持区块链项目，趣出行及华为阿里等企业第二批区块链备案成功
      </h1>
      <h1>2019-10-29</h1>
      <p>
        10月25日中央电视台新闻联播报道了习近平总书记在10月24日下午率领全体政治局常委集体讨论学习区块链技术的现状和发展。习近平总书记在学习会上针对我国的区块链技术做了提纲挈领的讲话。消息经新闻联播报道，整个区块链产业瞬间亢奋。从国家最高决策层传达出来的积极信号，使我国区块链产业有了更加明确的方向指导及稳定的政策环境。
      </p>
      <p>
        在世界经济全球化的日益演变和积极推动下，中国经济国际化的环境下，全球正在出现强劲的中心化管理一体化的浪潮，传统经济体发生重要变革，特别是贯穿国民衣食住行等方面的企业，区块链技术的普及势不可挡。趣出行着眼于出行市场，率先进入区块链+出行的新时代。
      </p>
      <p>
        随着国家政策的鼎力支持，
        区块链行业螺旋上升，日趋规范。在中华人民共和国国家互联网信息办公室10月18日发布的第二批区块链备案名单中，趣出行与阿里、华为等企业共同上榜，正式走上合规之路。
      </p>
      <p>
        随着合规的区块链从业者身份的逐渐明朗，比特币也开始了新一轮的拉升，虽然现在有所回调，但是可以说是值得被纪念的。趣出行创始人张冬冬曾表示，首先我们需要建立一个持币待涨的基本共识，然后精进业务从而建立第二个基本共识，在合情合理的合法的大前提下通过春运节点实现价值飞跃。我们相信，只有真正落地的项目，币价才有价值。
      </p>
      <p>
        日前，趣出行的落地活动正在肇庆正如火如荼地开展。共享出行俨然成为市场上的刚需，为加速趣出行的落地进程，趣出行特别委派重量级讲师以及客服小姐姐亲临肇庆为大家答疑解惑。随着大家对趣出行理解的加深，我们必将迎来更多新生力量的加入，不断推动趣出行的健康发展，践行时代赋予趣出行的使命——“打造新时代拼车生活新体验，成为区块链+出行领域的第一品牌”。
      </p>
      <p>
        从区块链行业现在的蓬勃发展中我们不难发现，10月25日的新闻重要意义远超94，标志着中国区块链已经进入一个新的时代。由政策上的兼容并包，再到意识形态上的开拓创新，目的是迎头赶上，最终目标做到世界领先。
      </p>
      <p>
        趣出行也将在出行领域不断深耕，成为出行领域“独角兽”。目前趣出行用户群体已超过400万。今年，趣出行也将会加大重要落地城市之一肇庆的业务布局，计划肇庆地区将会达到20万用户的规模，创新了人人可参与的社群经济新篇章。
      </p>
      <p>
        未来，相信随着区块链技术的成长与崛起，趣出行必将建立起一个公平、公正、共享、共荣共生的去中心化出行新形态。
      </p>
    </div>

    <div v-if="this.$route.query.path == 'page4'">
      <h1>趣出行百城计划——深圳站大会盛况回顾</h1>
      <h1>2020-06-20</h1>
      <p>
        深圳，2020年6月20日 -
        趣出行于当日在深圳海景嘉图酒店的一品厅成功举办了盛大的万条线路深圳站大会，从下午2点一直持续到傍晚5:30。这场盛宴旨在回顾趣出行的发展历程，探讨未来的发展方向，以及表彰在这一旅途中努力耕耘的合作伙伴。
      </p>
      <p>
        主持人一声令下，趣出行2020年10000条线路深圳站大会正式拉开帷幕。首位登场的是趣出行的创始人和CEO，张冬冬先生。他在致辞中表达了对与会者的诚挚问候，并特别强调了对健康与安全的重视。他坚定地表示，在从北京赶来的途中，亲自进行了两次核酸检测，并确认结果为阴性，以确保与会者的安全。这一动作赢得了全场热烈的掌声，展现了他对参会者的尊重和关怀。
      </p>

      <div>
        <h3>张冬冬先生的致辞内容主要包括：</h3>
        <p>
          2020年趣出行的主要任务：
          张冬冬先生分享了趣出行在过去一年中的主要任务和成就，强调了公司的成长和发展。
        </p>
        <p>
          10000条线路的运营及趣出行的优势：
          他讲述了趣出行如何成功运营了10000条线路，并介绍了公司在拼车行业中的竞争优势。
        </p>
        <p>
          趣出行的发展历程：
          张冬冬先生回顾了趣出行的发展历程，从初创阶段到如今的规模化运营，强调了团队的坚韧和不懈努力。
        </p>
        <p>
          DAPP的升级优化：
          他分享了趣出行的移动应用程序（DAPP）的升级和优化计划，以提供更好的用户体验。
        </p>
        <p>
          CPLE的价值：
          张冬冬先生解释了趣出行生态系统中通证（CPLE）的价值和作用，以及它如何激励用户和合作伙伴的积极参与。
        </p>
        <p>
          接下来，会议进入了圆桌论坛环节，与会者包括趣出行的高管团队、地推王、合作伙伴、布道者以及嘉宾主持。他们就出行行业的未来、区块链技术的应用，以及趣出行如何通过区块链技术实现行业性革命等话题展开了深入的讨论。
        </p>
        <p>
          随后，是本次大会的颁奖仪式，表彰了在拼车行业中取得杰出成就的合作伙伴。这些奖项不仅代表了对他们的认可，还传递了趣出行对合作伙伴们的感激之情。
        </p>
        <p>
          张冬冬先生表示：“一分耕耘就会有一分收获。”他强调了每位合作伙伴对于趣出行快速增长的重要性，承诺将与他们携手前行，共同走得更远。
        </p>
        <p>
          会议的最后部分是答疑解惑环节，趣出行的工作人员耐心解答了与会者提出的各种问题。这次成功的深圳站大会不仅是一个结束，更是一个新的起点。趣出行计划在今年年底前扩展到200座城市，不断追求卓越。无论是新朋友还是老朋友，趣出行期待与用户一起携手前行，共创美好未来。
        </p>
      </div>
    </div>

    <div v-if="this.$route.query.path == 'page5'">
      <h1>趣出行赞助支持“夏河香浪节” 为文化旅游注入新活力</h1>
      <h1>2020-08-25</h1>
      <p>
        2020年九色甘南香巴拉第三届夏河拉卜楞香浪节在美丽的甘加赛钦滩草原于8月23—24日盛大开幕。这场传统节日吸引了5万多各族同胞共同参与，欢聚一堂，庆祝这一非遗文化庆典。
      </p>
      <p>香浪节：传统与现代的交融</p>
      <p>
        香浪节源于甘肃省甘南地区的传统，起初是拉卜楞寺僧人每年采集木柴的活动，逐渐演变成了一项集合僧俗、与自然亲近的节日。节名“香浪”源自藏语，意为采薪。这一节日的庆祝活动包括舞蹈、音乐、比赛、半程马拉松赛、音乐节和篝火晚会等多种文体活动。
      </p>

      <div>
        <h3>趣出行的赞助支持</h3>
        <p>
          趣出行非常荣幸能够成为“夏河香浪节”的赞助支持单位。作为国家绿色共享出行的倡导者，趣出行基于区块链技术，推出了新一代顺风车平台。他们的独特之处在于长期顺路合乘、车接送外的站点加快车模式等创新服务，旨在为用户提供更方便、快捷、环保、节能的出行选择。
        </p>
        <p>
          对于香浪节，趣出行提供了特殊的支持，从夏河机场、兰州机场到拉卜楞镇的往返接送服务，趣出行的车队带有独特的车贴标识，留下了美丽的车辙。他们以仅需2元的价格提供服务，得到了当地人和游客的一致好评。
        </p>
      </div>

      <div>
        <h3>推动文化旅游产业升级</h3>
        <p>
          趣出行深刻认识到区块链技术与出行产业的天然契合度，这一技术的特点，如去中心化、去信任化、不可篡改、高度透明和强大的安全性，为传统出行产业带来了更高效的协同合作方式。趣出行将车主、乘客、社群和周边产业连接在一起，构建了一个互利的利益共同体，推动整个产业进入了良性发展的快车道。
        </p>
      </div>

      <div>
        <p>重视乘客体验与公益出行</p>
        <p>
          趣出行以乘客体验为核心，致力于让价值贡献者获得价值。他们将车主和乘客视为共同利益的参与者，并根据其贡献的大小提供公平和公正的奖励。趣出行的百城计划旨在搭建10000条固定线路，开启了场景拼车的新纪元。
        </p>
        <p>
          趣出行的参与不仅丰富了夏河香浪节的活动内容，还为文化旅游产业注入了新的活力。他们的赞助支持体现了对非遗文化传承和可持续出行的重视，为夏河的旅游及非遗文化事业贡献了一份力量。趣出行承诺将继续深入挖掘旅游出行场景，满足更广泛的出行需求，为文化旅游产业用车拼车转型升级做出巨大的贡献。
        </p>
      </div>
    </div>

    <div v-if="this.$route.query.path == 'page6'">
      <h1>趣出行惊艳亮相北京地铁一号线，彰显时代民族企业精神</h1>
      <h1>2021-07-25</h1>
      <p>
        2020年6月18日，北京 –
        北京地铁一号线，这条承载着千百年文化底蕴的轨道，也见证着中国企业的崛起与创新。在这个炎炎夏日，一号线不仅承载着旅客，还迎来了一场特殊的“中国企业”主题巡展。
      </p>
      <p>
        趣出行，作为中国出行领域的新星，首次登上北京地铁一号线的舞台，同时也成为这场“中国企业”巡展的杰出代表之一。这场活动不仅突显了企业的实力，更是对其多年在出行领域积累的认可。
      </p>

      <div>
        <h3>在时代前沿，区块链+顺风车的成功方程</h3>
        <p>
          为什么趣出行能够在短短几年内崭露头角，成为出行领域的领军企业，并成功入选“中国企业”巡展呢？关键在于他们聚焦了“Z时代”的消费新需求，坚持长期主义。
        </p>
        <p>
          新兴消费人群的兴起伴随着消费理念的不断更新和市场机遇的涌现。趣出行正是抓住了这一机会，以碎片化、个性化和社交化的需求为出发点，将区块链技术与顺风车服务相融合。这个决策让他们在市场中脱颖而出，因为他们明白，“Z时代”的消费者需要更多的个性选择和社交互动。
        </p>
      </div>

      <div>
        <h3>引领未来出行的力量</h3>
        <h3>
          趣出行之所以能够在竞争激烈的出行市场脱颖而出，正是因为他们始终秉持着引领未来出行的使命。他们深知，现代人对出行的需求已不再仅仅停留在传统的交通工具之上，更追求着个性化、安全、高效、绿色的出行方式。
        </h3>
      </div>

      <div>
        <h3>区块链技术：出行安全的坚实防线</h3>
        <p>
          趣出行认识到，在出行领域，安全永远是首要问题。而区块链技术的去中心化、公开透明、不可篡改等特性，恰好能够为乘客和车主提供更可靠的安全保障。趣出行将用户信息和交易数据都储存在区块链上，不仅保证了数据的安全性，也使得任何一次交易都能够被追溯，降低了潜在的风险。
        </p>
      </div>

      <div>
        <p>用户至上：破解传统痛点</p>
        <p>
          在出行市场，传统的痛点包括高额的服务费用、不透明的费用结构以及不稳定的服务质量。趣出行秉承着用户至上的原则，打破了这些困扰用户的问题。
        </p>
        <p>
          他们采用了明确的用户按座公益收费模式，车主不必绕路，并提供积分和红包等多种奖励方式，确保用户和车主都能够获得公平的回报。这种共赢模式不仅让用户感受到实惠，也让车主愿意为服务质量努力提升。
        </p>
      </div>

      <div>
        <h3>连接生活：出行新体验</h3>
        <p>
          趣出行不仅仅停留在提供便捷的交通工具这一层面，他们致力于连接出行与生活，让出行变得更有趣、更有价值。通过实名认证、个人名片夹、线上社交圈等方式，趣出行为用户提供了更多交流和社交的机会。这种社交化的拼车方式打破了传统模式的局限性，让出行不再是孤独的旅程，而是一个充满乐趣和可能性的体验。
        </p>
      </div>

      <div>
        <h3>绿色出行的使命</h3>
        <p>
          趣出行深刻认识到，在碳中和与绿色可持续发展的时代背景下，出行行业需要积极响应环保呼声。他们将区块链技术与绿色出行理念相结合，致力于降低碳排放，提供更环保的出行选择。这一使命不仅符合时代的需求，也为趣出行赢得了更多用户的支持。
        </p>
      </div>

      <div>
        <h3>未来前景展望</h3>
        <p>
          趣出行的发展不仅是一家企业的崛起，更是出行行业的一次革命。他们以区块链技术为驱动力，将出行与安全、用户至上、社交和环保等多个维度相结合，为用户提供了更加完善的出行体验。未来，我们可以期待趣出行在出行领域继续引领创新，为未来出行带来更多可能性。这是一家以技术和使命为核心的企业，也是一家值得期待的未来力量。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import "./index.scss";
export default {
  data() {
    return {
      pageWidth: 0,
    };
  },

  mounted() {
    this.pageWidth = window.innerWidth;
  },
};
</script>
